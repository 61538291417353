import React, { ReactElement } from "react";

export default function Logo(): ReactElement {
    return (
        <svg
            width="350"
            height="28"
            viewBox="0 0 350 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_142_1295)">
                <rect x="5" y="4" width="31" height="21" rx="1" fill="white" />
                <rect x="1" y="1" width="31" height="21" rx="3" fill="white" />
                <path
                    d="M54.4608 12.9705C55.4465 13.2234 56.3089 13.8213 56.8914 14.6557C57.473 15.5067 57.776 16.5174 57.7583 17.548C57.7685 18.2017 57.6468 18.8508 57.4004 19.4563C57.154 20.0619 56.788 20.6115 56.3242 21.0723C55.7996 21.5895 55.1724 21.9908 54.4829 22.2504C53.7935 22.5099 53.0574 22.622 52.3219 22.5793H45.0303V4.95775H51.9817C52.6351 4.91814 53.2897 5.01672 53.9025 5.24706C54.5153 5.4774 55.0727 5.83432 55.5384 6.29456C55.9482 6.69326 56.273 7.17093 56.493 7.6987C56.713 8.22648 56.8237 8.79339 56.8185 9.36516C56.8285 10.1444 56.6056 10.909 56.1784 11.5608C55.7769 12.2072 55.1731 12.7028 54.4608 12.9705ZM51.6981 7.21007H47.4122V12.0793H51.6252C51.9957 12.0987 52.3663 12.0427 52.7144 11.9146C53.0626 11.7864 53.3811 11.5889 53.6506 11.3339C53.8981 11.1003 54.0938 10.8173 54.225 10.5033C54.3562 10.1893 54.42 9.85123 54.4122 9.511C54.4119 9.20318 54.3451 8.89906 54.2165 8.61941C54.0878 8.33976 53.9004 8.09117 53.6668 7.89063C53.1243 7.41265 52.4209 7.1579 51.6981 7.17766V7.21007ZM47.4122 20.3594H51.9412C52.3848 20.3826 52.8286 20.3164 53.2461 20.1647C53.6637 20.013 54.0465 19.7789 54.3717 19.4763C54.6653 19.2117 54.8993 18.8878 55.0586 18.5261C55.2178 18.1644 55.2985 17.7731 55.2953 17.3779C55.3036 16.965 55.2241 16.555 55.0621 16.1751C54.9001 15.7952 54.6592 15.454 54.3555 15.1742C53.6598 14.5561 52.7491 14.236 51.8196 14.283H47.3879L47.4122 20.3594Z"
                    fill="#384C81"
                />
                <path
                    d="M72.0016 15.9447C72.0016 16.1068 71.9773 16.4957 71.9287 17.1114H61.7528C61.9133 18.1409 62.4258 19.0829 63.203 19.7769C64.0612 20.471 65.1473 20.8205 66.2493 20.7572C67.8415 20.7243 69.3898 20.2289 70.7053 19.3313L71.6694 21.0732C70.9154 21.6274 70.0787 22.0594 69.1903 22.3533C68.2239 22.6902 67.2079 22.8628 66.1845 22.8637C65.1299 22.8825 64.0824 22.6868 63.1058 22.2885C62.276 21.9571 61.5412 21.4255 60.9669 20.741C60.4523 20.0972 60.0497 19.3714 59.7759 18.594C59.5078 17.8117 59.371 16.9905 59.3708 16.1635C59.3382 15.283 59.4795 14.4047 59.7868 13.5789C60.094 12.7531 60.5611 11.996 61.1613 11.351C61.7639 10.728 62.4908 10.2388 63.2948 9.91494C64.0988 9.59112 64.9618 9.43999 65.828 9.47135C66.647 9.43922 67.4638 9.57745 68.2266 9.87729C68.9894 10.1771 69.6818 10.632 70.2597 11.2132C70.8564 11.8421 71.3187 12.586 71.6182 13.3995C71.9177 14.213 72.0481 15.0791 72.0016 15.9447ZM61.7528 15.1345H69.6845C69.6784 14.6442 69.574 14.16 69.3776 13.7106C69.1813 13.2612 68.8968 12.8557 68.5411 12.5181C68.1853 12.1805 67.7656 11.9176 67.3065 11.745C66.8475 11.5724 66.3585 11.4935 65.8685 11.513C64.8832 11.4977 63.9295 11.86 63.203 12.5257C62.4371 13.2053 61.9256 14.1254 61.7528 15.1345V15.1345Z"
                    fill="#384C81"
                />
                <path
                    d="M77.7858 22.8562C76.8215 22.8594 75.8728 22.6138 75.0311 22.1432C74.1837 21.6656 73.4918 20.954 73.0381 20.0935L74.6585 18.8296C74.9788 19.4461 75.4604 19.9641 76.052 20.3284C76.5864 20.6644 77.2031 20.8466 77.8344 20.8551C78.4174 20.8783 78.9903 20.6978 79.4548 20.3446C79.6503 20.1987 79.8093 20.0093 79.9189 19.7913C80.0286 19.5734 80.0861 19.3329 80.0867 19.0889C80.073 18.734 79.955 18.3911 79.7475 18.1029C79.54 17.8147 79.2522 17.594 78.92 17.4685C78.7094 17.3551 78.1828 17.1201 77.2997 16.7555C76.4166 16.3909 75.9791 16.1803 75.7279 16.0426C75.1555 15.7611 74.6744 15.3234 74.3403 14.78C74.0063 14.2366 73.8329 13.6098 73.8402 12.972C73.8292 12.4896 73.9261 12.0109 74.1237 11.5708C74.3214 11.1307 74.6148 10.7402 74.9825 10.428C75.8189 9.70715 76.902 9.33836 78.0045 9.39903C78.8121 9.39612 79.6098 9.57617 80.3378 9.92566C81.0421 10.2506 81.6382 10.7708 82.0555 11.4245L80.6052 12.8099C80.31 12.3527 79.9043 11.9772 79.4257 11.7181C78.9472 11.459 78.411 11.3246 77.8668 11.3273C77.3814 11.305 76.905 11.4638 76.53 11.7729C76.369 11.9089 76.2404 12.0791 76.1535 12.2712C76.0666 12.4632 76.0236 12.6721 76.0277 12.8828C76.0328 13.2117 76.1378 13.5312 76.3288 13.7989C76.5198 14.0666 76.7878 14.2699 77.0971 14.3817L78.5068 14.9974C79.0959 15.231 79.6695 15.5016 80.2244 15.8076C80.8675 16.0698 81.4173 16.5187 81.8028 17.0963C82.1884 17.674 82.3921 18.3539 82.3876 19.0483C82.3933 19.5762 82.2813 20.0988 82.0599 20.5781C81.8385 21.0573 81.5132 21.4813 81.1075 21.8192C80.1626 22.5561 78.9821 22.9247 77.7858 22.8562Z"
                    fill="#384C81"
                />
                <path
                    d="M91.9239 20.0434L92.7827 21.8015C91.8025 22.5404 90.5986 22.9208 89.3718 22.8791C88.8044 22.918 88.2355 22.8265 87.7089 22.6116C87.1823 22.3968 86.7118 22.0642 86.3336 21.6395C85.5815 20.6153 85.2143 19.3589 85.2966 18.0909V11.7471H83.0361V9.79456H85.3209V5.26562H87.5651V9.79456H92.0859V11.7471H87.5651V18.2286C87.5651 19.9138 88.2537 20.7564 89.631 20.7564C90.4479 20.7438 91.2439 20.4963 91.9239 20.0434Z"
                    fill="#384C81"
                />
                <path
                    d="M107.933 22.8311C106.746 22.8594 105.566 22.6503 104.461 22.2163C103.356 21.7822 102.35 21.1318 101.5 20.3034C100.636 19.465 99.9569 18.4554 99.5063 17.3392C99.0556 16.223 98.8433 15.0249 98.8828 13.8219C98.8419 12.5998 99.058 11.3828 99.5171 10.2495C99.9763 9.11626 100.668 8.09205 101.548 7.24317C102.399 6.39708 103.411 5.73134 104.525 5.28585C105.638 4.84036 106.831 4.62429 108.03 4.65058C109.573 4.63592 111.095 5.00333 112.462 5.72002C113.748 6.34834 114.842 7.31093 115.629 8.50705L113.863 9.98969C113.224 9.0459 112.362 8.27513 111.352 7.74635C110.343 7.21757 109.218 6.94726 108.078 6.9596C107.188 6.94008 106.304 7.10409 105.48 7.44142C104.656 7.77874 103.911 8.28215 103.29 8.92025C102.644 9.56541 102.137 10.336 101.8 11.1845C101.462 12.033 101.303 12.9416 101.33 13.8543C101.299 14.7424 101.452 15.6273 101.777 16.454C102.103 17.2807 102.596 18.0316 103.224 18.66C103.853 19.2883 104.603 19.7807 105.43 20.1067C106.257 20.4326 107.142 20.585 108.03 20.5545C109.176 20.5552 110.305 20.2772 111.319 19.7443C112.343 19.218 113.237 18.4708 113.936 17.5568L115.654 19.0071C114.8 20.1923 113.667 21.1494 112.356 21.7941C110.985 22.4867 109.469 22.8422 107.933 22.8311V22.8311Z"
                    fill="#384C81"
                />
                <path
                    d="M127.637 11.6595V9.79605H129.832V22.5727H127.637V20.7579C127.108 21.4381 126.428 21.9847 125.649 22.3534C124.871 22.7221 124.017 22.9027 123.156 22.8805C122.298 22.9024 121.445 22.7393 120.655 22.4024C119.865 22.0655 119.157 21.5627 118.579 20.928C117.36 19.6081 116.708 17.8625 116.764 16.0669C116.733 15.2006 116.876 14.3369 117.185 13.527C117.494 12.7171 117.963 11.9774 118.562 11.3516C119.154 10.7352 119.869 10.2505 120.66 9.92941C121.452 9.6083 122.302 9.45794 123.156 9.48818C124.02 9.47808 124.875 9.66899 125.652 10.0458C126.43 10.4226 127.109 10.9751 127.637 11.6595V11.6595ZM127.782 16.156C127.814 14.9588 127.378 13.7962 126.567 12.9153C126.167 12.4778 125.676 12.1325 125.129 11.9031C124.582 11.6737 123.992 11.5657 123.399 11.5866C122.81 11.566 122.224 11.6742 121.681 11.9037C121.138 12.1332 120.651 12.4784 120.256 12.9153C119.487 13.8294 119.065 14.9857 119.065 16.1803C119.065 17.3749 119.487 18.5312 120.256 19.4454C120.649 19.8863 121.134 20.2349 121.678 20.466C122.221 20.6971 122.809 20.8051 123.399 20.7822C123.992 20.8063 124.584 20.6998 125.131 20.4702C125.678 20.2406 126.169 19.8935 126.567 19.4535C127.388 18.5556 127.824 17.3718 127.782 16.156V16.156Z"
                    fill="#384C81"
                />
                <path
                    d="M135.196 22.5713H132.879V9.79473H135.05V11.6744C135.37 10.9807 135.891 10.399 136.545 10.0047C137.2 9.61033 137.957 9.42148 138.72 9.46255C139.225 9.469 139.728 9.5315 140.219 9.6489L140.009 11.8688C139.576 11.7303 139.126 11.6593 138.672 11.6582C138.196 11.6394 137.723 11.7255 137.285 11.9103C136.846 12.0951 136.454 12.3742 136.136 12.7276C135.44 13.5934 135.095 14.6898 135.172 15.7982L135.196 22.5713Z"
                    fill="#384C81"
                />
                <path
                    d="M151.181 11.6589V4.5293H153.465V22.5721H151.181V20.7816C150.662 21.4585 149.989 22.002 149.219 22.3669C148.448 22.7318 147.601 22.9077 146.749 22.88C145.887 22.9023 145.03 22.7421 144.234 22.4098C143.438 22.0774 142.721 21.5806 142.131 20.9517C140.92 19.6531 140.268 17.9304 140.316 16.1555C140.292 15.2885 140.439 14.4253 140.751 13.6159C141.062 12.8065 141.532 12.067 142.131 11.4402C142.707 10.8049 143.414 10.3015 144.202 9.9645C144.991 9.6275 145.843 9.46484 146.7 9.48763C147.566 9.46917 148.423 9.65638 149.202 10.0339C149.981 10.4113 150.659 10.9683 151.181 11.6589V11.6589ZM151.351 16.1555C151.383 14.9582 150.947 13.7957 150.136 12.9147C149.74 12.4779 149.253 12.1327 148.711 11.9032C148.168 11.6737 147.581 11.5654 146.992 11.586C146.398 11.5651 145.807 11.673 145.258 11.9023C144.71 12.1317 144.218 12.4771 143.816 12.9147C143.023 13.8051 142.597 14.9634 142.625 16.1555C142.589 17.366 143.014 18.5451 143.816 19.4529C144.216 19.8933 144.708 20.2411 145.256 20.472C145.805 20.7028 146.397 20.8113 146.992 20.7897C147.582 20.8093 148.169 20.6998 148.712 20.4689C149.255 20.238 149.741 19.8912 150.136 19.4529C150.956 18.555 151.393 17.3712 151.351 16.1555V16.1555Z"
                    fill="#384C81"
                />
                <path
                    d="M162.377 4.95789H168.267C169.006 4.90297 169.748 5.00409 170.445 5.25467C171.142 5.50525 171.779 5.8997 172.314 6.41238C172.849 6.92507 173.27 7.54453 173.55 8.23053C173.829 8.91654 173.962 9.65373 173.938 10.3942C173.952 11.1058 173.824 11.8129 173.562 12.4749C173.301 13.1368 172.911 13.7404 172.415 14.2507C171.829 14.7987 171.137 15.2208 170.382 15.4911C169.627 15.7614 168.824 15.8742 168.024 15.8225H164.743V22.5713H162.353L162.377 4.95789ZM164.767 13.6107H168.097C168.564 13.6382 169.033 13.5697 169.473 13.4095C169.913 13.2492 170.316 13.0006 170.657 12.679C171.238 12.0626 171.568 11.2513 171.581 10.4043C171.595 9.55736 171.291 8.73595 170.73 8.10141C170.429 7.79579 170.068 7.55756 169.668 7.40251C169.269 7.24746 168.841 7.17915 168.413 7.2021H164.767V13.6107Z"
                    fill="#384C81"
                />
                <path
                    d="M178.249 22.5708H175.964V9.79418H178.127V11.6738C178.449 10.9815 178.97 10.4011 179.624 10.007C180.278 9.61291 181.035 9.42314 181.797 9.462C182.305 9.4689 182.81 9.53139 183.304 9.64835L183.085 11.8683C182.656 11.7303 182.208 11.6593 181.757 11.6576C181.28 11.6376 180.805 11.7231 180.365 11.908C179.925 12.0929 179.532 12.3725 179.213 12.727C178.52 13.5947 178.176 14.6897 178.249 15.7976V22.5708Z"
                    fill="#384C81"
                />
                <path
                    d="M190.069 22.8788C189.17 22.9092 188.274 22.7537 187.437 22.4219C186.601 22.0901 185.842 21.5892 185.208 20.9506C184.591 20.3165 184.107 19.5656 183.784 18.742C183.461 17.9184 183.306 17.0386 183.328 16.1543C183.308 15.2679 183.468 14.3867 183.799 13.5641C184.13 12.7415 184.624 11.9946 185.252 11.3689C185.88 10.7431 186.629 10.2514 187.453 9.92363C188.277 9.59587 189.158 9.43886 190.045 9.46213C190.935 9.43412 191.821 9.59345 192.646 9.92981C193.471 10.2662 194.216 10.772 194.833 11.4147C196.056 12.7008 196.738 14.4078 196.738 16.1826C196.738 17.9574 196.056 19.6644 194.833 20.9506C194.212 21.5798 193.469 22.0754 192.65 22.407C191.831 22.7386 190.953 22.8992 190.069 22.8788V22.8788ZM194.476 16.1543C194.489 15.5554 194.382 14.96 194.161 14.4034C193.939 13.8468 193.609 13.3402 193.188 12.9135C192.789 12.4862 192.306 12.1455 191.769 11.9126C191.233 11.6796 190.654 11.5594 190.069 11.5594C189.484 11.5594 188.905 11.6796 188.369 11.9126C187.832 12.1455 187.349 12.4862 186.95 12.9135C186.529 13.3402 186.199 13.8468 185.977 14.4034C185.756 14.96 185.649 15.5554 185.662 16.1543C185.645 17.3625 186.108 18.528 186.95 19.395C187.348 19.8245 187.83 20.1671 188.367 20.4014C188.904 20.6357 189.483 20.7567 190.069 20.7567C190.655 20.7567 191.234 20.6357 191.771 20.4014C192.307 20.1671 192.79 19.8245 193.188 19.395C194.021 18.5241 194.475 17.359 194.452 16.1543H194.476Z"
                    fill="#384C81"
                />
                <path
                    d="M204.985 22.8549C204.088 22.8882 203.195 22.7379 202.359 22.4132C201.523 22.0886 200.762 21.5965 200.123 20.9672C198.902 19.6639 198.223 17.9447 198.223 16.1587C198.223 14.3727 198.902 12.6535 200.123 11.3503C200.78 10.7184 201.558 10.2261 202.41 9.90306C203.262 9.58002 204.171 9.43292 205.082 9.47062C206.298 9.45938 207.495 9.77261 208.549 10.378C209.557 10.9265 210.367 11.7764 210.866 12.8086L209.035 13.9185C208.631 13.2133 208.054 12.6223 207.358 12.2009C206.686 11.7951 205.915 11.5821 205.13 11.5852C204.53 11.5731 203.934 11.6824 203.377 11.9066C202.82 12.1307 202.314 12.4651 201.89 12.8896C201.072 13.7853 200.619 14.9541 200.619 16.1668C200.619 17.3795 201.072 18.5484 201.89 19.444C202.286 19.8658 202.766 20.2004 203.299 20.4264C203.832 20.6524 204.406 20.7648 204.985 20.7565C205.795 20.7537 206.59 20.5326 207.285 20.1165C207.962 19.7331 208.522 19.173 208.906 18.4961L210.818 19.5898C210.269 20.5985 209.438 21.426 208.428 21.9718C207.376 22.5593 206.189 22.8635 204.985 22.8549V22.8549Z"
                    fill="#384C81"
                />
                <path
                    d="M224.688 15.9449C224.688 16.1069 224.664 16.4958 224.616 17.1116H214.448C214.576 18.1815 215.115 19.1598 215.951 19.84C216.787 20.5202 217.854 20.8491 218.928 20.7574C220.52 20.7245 222.069 20.229 223.384 19.3315L224.356 21.0653C223.602 21.6195 222.766 22.0515 221.877 22.3453C220.914 22.6823 219.9 22.8548 218.879 22.8558C217.822 22.8745 216.772 22.6788 215.793 22.2805C214.964 21.9513 214.231 21.4192 213.662 20.7331C213.141 20.0933 212.738 19.3664 212.471 18.5861C212.199 17.8048 212.062 16.9828 212.066 16.1555C212.011 14.395 212.651 12.6836 213.848 11.3916C214.452 10.7681 215.18 10.2785 215.986 9.95466C216.791 9.63083 217.656 9.48002 218.523 9.51202C219.342 9.48137 220.158 9.62028 220.921 9.92003C221.683 10.2198 222.376 10.6739 222.955 11.2539C223.545 11.8783 224.003 12.6159 224.301 13.4221C224.599 14.2283 224.731 15.0864 224.688 15.9449ZM214.448 15.1347H222.331C222.327 14.6444 222.224 14.1598 222.029 13.71C221.834 13.2601 221.55 12.8541 221.195 12.5162C220.84 12.1782 220.42 11.9153 219.961 11.743C219.502 11.5706 219.013 11.4925 218.523 11.5132C217.535 11.4957 216.578 11.8582 215.849 12.5259C215.098 13.2099 214.603 14.1305 214.448 15.1347V15.1347Z"
                    fill="#384C81"
                />
                <path
                    d="M230.482 22.8551C229.518 22.8583 228.569 22.6127 227.727 22.1421C226.88 21.6666 226.19 20.9542 225.742 20.0924L227.363 18.8285C227.681 19.4466 228.163 19.9652 228.756 20.3273C229.287 20.6642 229.902 20.8465 230.53 20.8539C231.113 20.8772 231.686 20.6967 232.151 20.3435C232.346 20.1976 232.505 20.0082 232.615 19.7902C232.725 19.5722 232.782 19.3317 232.783 19.0877C232.769 18.7329 232.651 18.3899 232.444 18.1017C232.236 17.8135 231.948 17.5929 231.616 17.4674C231.413 17.3539 230.879 17.119 229.996 16.7544C229.113 16.3898 228.675 16.1792 228.424 16.0414C227.85 15.7612 227.367 15.3239 227.032 14.7805C226.696 14.237 226.521 13.6095 226.528 12.9708C226.512 12.4831 226.607 11.9981 226.805 11.5519C227.002 11.1058 227.298 10.7102 227.67 10.3944C228.508 9.6751 229.59 9.30653 230.692 9.3655C231.502 9.3636 232.303 9.54356 233.034 9.89212C233.735 10.2183 234.328 10.7384 234.743 11.391L233.293 12.7764C232.998 12.3192 232.592 11.9437 232.114 11.6846C231.635 11.4255 231.099 11.2911 230.555 11.2937C230.069 11.2715 229.593 11.4303 229.218 11.7393C229.057 11.8754 228.928 12.0456 228.841 12.2376C228.755 12.4296 228.712 12.6386 228.716 12.8493C228.721 13.1791 228.827 13.4993 229.02 13.7672C229.213 14.035 229.482 14.2377 229.793 14.3481L231.195 14.9639C231.784 15.1975 232.357 15.4681 232.912 15.7741C233.556 16.036 234.107 16.4845 234.494 17.062C234.881 17.6396 235.086 18.3196 235.084 19.0148C235.09 19.5436 234.978 20.0671 234.755 20.5467C234.532 21.0263 234.204 21.4497 233.795 21.7856C232.858 22.5336 231.68 22.9139 230.482 22.8551Z"
                    fill="#384C81"
                />
                <path
                    d="M240.795 22.8558C239.834 22.858 238.888 22.6124 238.049 22.1428C237.201 21.6652 236.509 20.9536 236.056 20.0931L237.676 18.8292C237.996 19.4457 238.478 19.9637 239.07 20.328C239.602 20.6626 240.215 20.8447 240.844 20.8547C241.427 20.8772 241.999 20.6968 242.464 20.3442C242.66 20.1983 242.819 20.0089 242.928 19.7909C243.038 19.573 243.096 19.3325 243.096 19.0884C243.082 18.7336 242.964 18.3906 242.757 18.1025C242.55 17.8143 242.262 17.5936 241.93 17.4681C241.719 17.3547 241.184 17.1197 240.309 16.7551C239.434 16.3905 238.997 16.1799 238.737 16.0421C238.165 15.7603 237.683 15.3228 237.348 14.7796C237.012 14.2365 236.837 13.6098 236.842 12.9715C236.831 12.4892 236.927 12.0105 237.125 11.5704C237.323 11.1303 237.616 10.7398 237.984 10.4276C238.824 9.70846 239.909 9.34007 241.014 9.39863C241.822 9.39572 242.619 9.57576 243.347 9.92525C244.052 10.2501 244.648 10.7704 245.065 11.4241L243.607 12.8095C243.311 12.3523 242.906 11.9768 242.427 11.7177C241.949 11.4586 241.412 11.3242 240.868 11.3269C240.385 11.3059 239.912 11.4646 239.539 11.7725C239.377 11.9074 239.248 12.0774 239.161 12.2696C239.074 12.4619 239.031 12.6715 239.037 12.8824C239.042 13.2113 239.147 13.5308 239.338 13.7985C239.529 14.0662 239.797 14.2695 240.107 14.3813L241.516 14.997C242.103 15.2303 242.674 15.5009 243.226 15.8072C243.871 16.0672 244.423 16.5153 244.81 17.0933C245.198 17.6712 245.402 18.3523 245.397 19.0479C245.403 19.5768 245.291 20.1003 245.068 20.5798C244.845 21.0594 244.517 21.4828 244.109 21.8188C243.167 22.5552 241.989 22.9238 240.795 22.8558V22.8558Z"
                    fill="#384C81"
                />
                <path
                    d="M248.735 7.98849C248.515 7.99517 248.296 7.95694 248.091 7.87615C247.887 7.79536 247.7 7.67369 247.544 7.51858C247.387 7.36733 247.262 7.18454 247.18 6.98213C247.098 6.77972 247.059 6.56222 247.066 6.34381C247.059 6.12906 247.098 5.91529 247.181 5.71687C247.263 5.51845 247.387 5.33995 247.544 5.19335C247.703 5.04261 247.89 4.92542 248.094 4.84884C248.299 4.77226 248.517 4.73788 248.735 4.74775C249.162 4.74525 249.573 4.9112 249.878 5.20955C250.031 5.35526 250.153 5.5313 250.236 5.72654C250.318 5.92178 250.359 6.13197 250.356 6.34381C250.356 6.77356 250.185 7.18571 249.881 7.48959C249.577 7.79347 249.165 7.96418 248.735 7.96418V7.98849ZM249.829 9.7952V22.5718H247.544V9.7952H249.829Z"
                    fill="#384C81"
                />
                <path
                    d="M255.217 22.5711H252.924V9.79447H255.168V11.6093C255.57 10.9039 256.164 10.3275 256.882 9.94727C257.599 9.56709 258.41 9.39888 259.219 9.46229C259.88 9.44498 260.538 9.55845 261.156 9.7962C261.773 10.0339 262.337 10.3913 262.816 10.8477C263.323 11.372 263.715 11.9971 263.964 12.6825C264.214 13.3678 264.317 14.098 264.266 14.8257V22.5711H262.006V15.4658C262.068 14.4356 261.751 13.4185 261.115 12.6058C260.82 12.2698 260.454 12.0039 260.043 11.8273C259.633 11.6507 259.188 11.5679 258.741 11.585C258.262 11.5724 257.786 11.6636 257.345 11.8524C256.905 12.0412 256.51 12.3231 256.189 12.6787C255.527 13.4626 255.18 14.4648 255.217 15.4901V22.5711Z"
                    fill="#384C81"
                />
                <path
                    d="M277.278 11.4648V9.79582H279.522V21.2599C279.565 22.1624 279.421 23.0639 279.101 23.9085C278.78 24.7532 278.289 25.5228 277.659 26.1697C276.993 26.791 276.21 27.273 275.356 27.5875C274.502 27.9019 273.593 28.0424 272.684 28.0007C270.649 28.0224 268.66 27.389 267.013 26.194L268.09 24.3792C269.406 25.3942 271.022 25.9416 272.684 25.9347C273.296 25.9665 273.908 25.8754 274.484 25.6666C275.06 25.4579 275.589 25.1358 276.038 24.7194C276.449 24.3238 276.772 23.8457 276.986 23.3166C277.2 22.7874 277.299 22.2191 277.278 21.6488V20.5875C276.732 21.2389 276.044 21.7576 275.268 22.1042C274.492 22.4509 273.647 22.6166 272.797 22.5886C271.947 22.6134 271.1 22.4588 270.313 22.135C269.525 21.8111 268.815 21.3253 268.228 20.709C267.624 20.0895 267.15 19.3552 266.834 18.5496C266.518 17.7439 266.367 16.8831 266.389 16.018C266.373 15.1563 266.532 14.3002 266.855 13.501C267.177 12.7018 267.658 11.9758 268.267 11.3663C268.877 10.7569 269.603 10.2764 270.402 9.9537C271.201 9.63098 272.057 9.47257 272.919 9.48795C273.747 9.46784 274.57 9.63519 275.325 9.97751C276.079 10.3198 276.747 10.8282 277.278 11.4648V11.4648ZM269.937 19.2588C270.339 19.6749 270.821 20.004 271.355 20.2256C271.889 20.4472 272.463 20.5566 273.04 20.547C273.622 20.5623 274.199 20.4556 274.737 20.2337C275.274 20.0118 275.759 19.6797 276.16 19.2588C276.574 18.8303 276.898 18.3222 277.111 17.7653C277.324 17.2083 277.422 16.6139 277.399 16.018C277.421 15.4296 277.323 14.8428 277.109 14.2939C276.896 13.7449 276.573 13.2453 276.16 12.8259C275.329 12.0012 274.207 11.5383 273.036 11.5383C271.866 11.5383 270.743 12.0012 269.913 12.8259C269.5 13.2453 269.177 13.7449 268.963 14.2939C268.75 14.8428 268.652 15.4296 268.674 16.018C268.654 16.6157 268.756 17.211 268.973 17.7681C269.19 18.3252 269.518 18.8323 269.937 19.2588V19.2588Z"
                    fill="#384C81"
                />
                <path
                    d="M300.773 22.571H297.84L293.295 15.4009H290.864V22.5548H288.434V4.95761H294.413C295.161 4.91526 295.91 5.03362 296.608 5.30473C297.307 5.57583 297.94 5.99341 298.464 6.52937C299.414 7.51232 299.936 8.83231 299.914 10.1995C299.928 11.3855 299.525 12.5388 298.776 13.4582C298.027 14.3777 296.979 15.005 295.814 15.2308L300.773 22.571ZM294.461 7.21802H290.864V13.181H294.008C294.956 13.2482 295.895 12.9593 296.641 12.3708C296.941 12.0967 297.179 11.7606 297.337 11.3857C297.495 11.0108 297.57 10.6061 297.556 10.1995C297.573 9.80798 297.509 9.41721 297.37 9.05093C297.231 8.68464 297.018 8.35047 296.746 8.06871C296.445 7.77643 296.086 7.54989 295.693 7.40345C295.3 7.25702 294.88 7.19387 294.461 7.21802V7.21802Z"
                    fill="#384C81"
                />
                <path
                    d="M312.52 11.6588V9.79541H314.716V22.572H312.52V20.7572C311.989 21.4399 311.304 21.9878 310.522 22.3565C309.739 22.7253 308.881 22.9046 308.016 22.8799C307.157 22.9007 306.305 22.7371 305.515 22.4003C304.725 22.0635 304.017 21.5612 303.438 20.9274C302.222 19.6066 301.573 17.8608 301.631 16.0662C301.599 15.1991 301.742 14.3343 302.053 13.5239C302.363 12.7136 302.835 11.9744 303.438 11.351C304.027 10.7345 304.739 10.2486 305.527 9.92478C306.316 9.60097 307.164 9.44649 308.016 9.47133C308.885 9.45998 309.745 9.65184 310.527 10.0316C311.309 10.4114 311.992 10.9686 312.52 11.6588V11.6588ZM312.666 16.1554C312.698 14.9581 312.262 13.7956 311.451 12.9146C311.049 12.477 310.557 12.1316 310.009 11.9022C309.46 11.6729 308.869 11.565 308.275 11.5859C307.686 11.5653 307.099 11.6736 306.556 11.9031C306.014 12.1326 305.527 12.4778 305.131 12.9146C304.362 13.8288 303.941 14.985 303.941 16.1797C303.941 17.3743 304.362 18.5306 305.131 19.4447C305.526 19.883 306.012 20.2298 306.555 20.4607C307.098 20.6916 307.685 20.8011 308.275 20.7815C308.869 20.8041 309.461 20.6969 310.009 20.4675C310.558 20.238 311.05 19.8917 311.451 19.4528C312.272 18.5549 312.708 17.3712 312.666 16.1554V16.1554Z"
                    fill="#384C81"
                />
                <path
                    d="M325.265 20.0434L326.124 21.8015C325.147 22.5404 323.946 22.9209 322.721 22.8791C322.154 22.918 321.585 22.8265 321.059 22.6116C320.532 22.3968 320.061 22.0642 319.683 21.6395C318.931 20.6153 318.564 19.3589 318.646 18.0909V11.7471H316.386V9.79456H318.67V5.26562H320.907V9.79456H325.436V11.7471H320.907V18.2286C320.907 19.9138 321.603 20.7564 322.981 20.7564C323.795 20.7427 324.588 20.4953 325.265 20.0434V20.0434Z"
                    fill="#384C81"
                />
                <path
                    d="M339.16 15.9445C339.16 16.1066 339.135 16.4954 339.087 17.1112H328.943C329.099 18.1385 329.606 19.0802 330.377 19.7767C331.236 20.4694 332.322 20.8187 333.423 20.757C334.228 20.7517 335.027 20.6177 335.789 20.36C336.527 20.1273 337.225 19.7801 337.855 19.3311L338.827 21.073C338.073 21.6272 337.237 22.0592 336.348 22.3531C335.382 22.69 334.366 22.8626 333.342 22.8635C332.288 22.8823 331.24 22.6866 330.264 22.2883C329.436 21.9535 328.704 21.4191 328.133 20.7327C327.618 20.0889 327.216 19.3631 326.942 18.5857C326.674 17.8034 326.537 16.9821 326.537 16.1552C326.471 14.3808 327.112 12.6528 328.319 11.3508C328.924 10.7297 329.653 10.2429 330.458 9.92182C331.264 9.60079 332.128 9.45275 332.994 9.48734C333.813 9.45522 334.63 9.59345 335.393 9.89328C336.156 10.1931 336.848 10.648 337.426 11.2292C338.021 11.8557 338.481 12.5971 338.779 13.4079C339.077 14.2188 339.207 15.0819 339.16 15.9445ZM328.911 15.1343H336.802C336.796 14.6447 336.692 14.1612 336.496 13.7123C336.3 13.2635 336.016 12.8584 335.661 12.5209C335.306 12.1834 334.888 11.9204 334.43 11.7472C333.971 11.5741 333.483 11.4944 332.994 11.5128C332.009 11.4975 331.055 11.8598 330.329 12.5255C329.587 13.2147 329.099 14.1337 328.943 15.1343H328.911Z"
                    fill="#384C81"
                />
                <path
                    d="M344.952 22.8549C343.988 22.8581 343.039 22.6125 342.198 22.1419C341.351 21.6664 340.661 20.954 340.213 20.0922L341.833 18.8283C342.157 19.4453 342.641 19.9632 343.235 20.3271C343.766 20.664 344.38 20.8463 345.009 20.8537C345.592 20.877 346.165 20.6965 346.63 20.3433C346.825 20.1974 346.984 20.008 347.094 19.79C347.203 19.572 347.261 19.3315 347.261 19.0875C347.248 18.7327 347.13 18.3897 346.922 18.1015C346.715 17.8133 346.427 17.5927 346.095 17.4672C345.884 17.3537 345.358 17.1188 344.474 16.7542C343.591 16.3896 343.154 16.179 342.903 16.0412C342.327 15.7621 341.843 15.3254 341.506 14.7818C341.169 14.2382 340.993 13.6102 340.999 12.9706C340.988 12.4883 341.085 12.0096 341.282 11.5695C341.48 11.1293 341.773 10.7389 342.141 10.4266C342.968 9.69809 344.046 9.31778 345.147 9.3653C345.957 9.3634 346.757 9.54336 347.488 9.89192C348.19 10.2181 348.783 10.7382 349.198 11.3908L347.748 12.7762C347.452 12.319 347.047 11.9435 346.568 11.6844C346.09 11.4253 345.553 11.2909 345.009 11.2935C344.524 11.2713 344.047 11.4301 343.672 11.7391C343.511 11.8752 343.383 12.0454 343.296 12.2374C343.209 12.4294 343.166 12.6384 343.17 12.8491C343.176 13.1789 343.282 13.4991 343.475 13.767C343.667 14.0348 343.937 14.2375 344.248 14.3479L345.649 14.9637C346.238 15.1973 346.812 15.4679 347.367 15.7739C348.01 16.0361 348.56 16.485 348.945 17.0626C349.331 17.6402 349.534 18.3201 349.53 19.0146C349.538 19.5429 349.427 20.0662 349.206 20.5458C348.984 21.0254 348.657 21.4491 348.25 21.7854C347.317 22.5297 346.145 22.9097 344.952 22.8549V22.8549Z"
                    fill="#384C81"
                />
                <path
                    d="M3.94531 22.5715C2.85837 22.43 1.85983 21.8985 1.13551 21.0758C0.411197 20.2532 0.010436 19.1953 0.0078125 18.0992V4.50434C0.00995428 3.31245 0.484383 2.16998 1.32718 1.32718C2.16998 0.484383 3.31245 0.00995428 4.50434 0.0078125H28.0726C29.2659 0.00995872 30.4096 0.485501 31.2526 1.33005C32.0957 2.1746 32.5692 3.31914 32.5692 4.51244H31.4916C31.4895 3.60633 31.1286 2.73794 30.4878 2.09722C29.8471 1.4565 28.9787 1.0956 28.0726 1.09346H4.50434C3.59757 1.09346 2.72794 1.45368 2.08676 2.09486C1.44557 2.73604 1.08536 3.60567 1.08536 4.51244V18.0992C1.08761 18.9287 1.38992 19.7293 1.93644 20.3533C2.48297 20.9773 3.2368 21.3824 4.05874 21.4939L3.94531 22.5715Z"
                    fill="#384C81"
                />
                <path
                    d="M36.0774 9.90039H4.26953V14.2754H36.0774V9.90039Z"
                    fill="#384C81"
                />
                <path
                    d="M34.8463 14.9883H5.51758V17.0299H34.8463V14.9883Z"
                    fill="#9ACA3C"
                />
                <path
                    d="M34.9192 8.92043C34.8016 8.02679 34.3642 7.20603 33.6879 6.61017C33.0116 6.01432 32.1423 5.68375 31.2409 5.67969H9.11476C8.21343 5.68375 7.34411 6.01432 6.66782 6.61017C5.99152 7.20603 5.55408 8.02679 5.43652 8.92043H34.9192Z"
                    fill="#9ACA3C"
                />
                <path
                    d="M33.7197 26.1042H6.6352C5.90677 26.1042 5.20818 25.8148 4.69311 25.2997C4.17803 24.7847 3.88867 24.0861 3.88867 23.3576V6.24653C3.88867 5.5181 4.17803 4.81952 4.69311 4.30444C5.20818 3.78937 5.90677 3.5 6.6352 3.5H33.7197C34.4481 3.5 35.1467 3.78937 35.6618 4.30444C36.1769 4.81952 36.4662 5.5181 36.4662 6.24653V23.3576C36.4662 24.0861 36.1769 24.7847 35.6618 25.2997C35.1467 25.8148 34.4481 26.1042 33.7197 26.1042ZM6.6352 4.58565C6.20164 4.58552 5.78521 4.75493 5.47487 5.0577C5.16454 5.36047 4.98489 5.77259 4.97432 6.20602V23.3576C4.97432 23.7981 5.1493 24.2206 5.46078 24.5321C5.77225 24.8435 6.19471 25.0185 6.6352 25.0185H33.7197C34.1531 25.0079 34.5652 24.8283 34.868 24.518C35.1708 24.2076 35.3402 23.7912 35.3401 23.3576V6.24653C35.3298 5.82001 35.1558 5.41382 34.8541 5.11214C34.5524 4.81046 34.1462 4.63644 33.7197 4.62616L6.6352 4.58565Z"
                    fill="#384C81"
                />
                <path
                    d="M33.3799 22.3775H26.8984V18.2051H33.3799V22.3775ZM27.8301 21.462H32.4644V19.1125H27.8301V21.462Z"
                    fill="#384C81"
                />
            </g>
            <defs>
                <clipPath id="clip0_142_1295">
                    <rect width="349.546" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
